import { Injectable } from '@angular/core';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class KweatherService {

  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      // baseURL: 'http://localhost:8000/v1/',
      baseURL: 'https://kweather.rudy.dev.br/v1/',
      headers: {
        'Accept' : '/',
        'Authorization': `Bearer ${environment.kweather}`,
        'Content-Type' : 'application/x-www-form-urlencoded'
      }
    });
  }

  public getLocalidades(): Promise<AxiosResponse> {
    return this.instance.get(`localidades`);
  }

  public getAtual(localidade_id: number): Promise<AxiosResponse> {
    return this.instance.get(localidade_id + `/atual`);
  }

  public getPrevisao(localidade_id: number): Promise<AxiosResponse> {
    return this.instance.get(localidade_id + `/previsao`);
  }

}
