<div class="container">
  <div
    class="title"
    (click)="isOpened = !isOpened"
    [ngClass]="{ inactive: !isOpened }"
  >
    <div class="text"><ng-content select="[title]"></ng-content></div>
    <div class="arrow">
      <span *ngIf="isOpened">❯</span>
      <span *ngIf="!isOpened">❮</span>
    </div>
  </div>
  <div class="content" [ngClass]="{ active: isOpened }">
    <ng-content select="[content]"></ng-content>
  </div>
</div>
