import { Component, Input, OnInit } from '@angular/core';
import { nomeCoordPorSigla } from '../utils/compasso';

@Component({
  selector: 'today',
  templateUrl: './today.component.html',
  styleUrls: [
    './today.component.scss',
    './../utils/common.scss'
  ]
})
export class TodayComponent implements OnInit {

  @Input('dados') public dados: any;
  @Input('localidade') public localidade: string = '...';

  public getPosicao(pos: string) {
    return nomeCoordPorSigla(pos);
  }

  constructor() { }

  ngOnInit() {
  }

}
