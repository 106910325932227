export const _16POINTS_OF_COMPASS = [
  {
    nome: 'Norte',
    sigla: 'N',
    grau: 0
  },
  {
    nome: 'Norte-Nordeste',
    sigla: 'NNE',
    grau: 22.5
  },
  {
    nome: 'Nordeste',
    sigla: 'NE',
    grau: 45
  },
  {
    nome: 'Leste-Nordeste',
    sigla: 'ENE',
    grau: 67.5
  },
  {
    nome: 'Leste',
    sigla: 'E',
    grau: 90
  },
  {
    nome: 'Leste-Sudeste',
    sigla: 'ESE',
    grau: 112.5
  },
  {
    nome: 'Sudeste',
    sigla: 'SE',
    grau: 135
  },
  {
    nome: 'Sul-sudeste',
    sigla: 'SSE',
    grau: 157.5
  },
  {
    nome: 'Sul',
    sigla: 'S',
    grau: 180
  },
  {
    nome: 'Sul-sudoeste',
    sigla: 'SSW',
    grau: 202.5
  },
  {
    nome: 'Sudoeste',
    sigla: 'SW',
    grau: 225
  },
  {
    nome: 'Oeste-sudoeste',
    sigla: 'WSW',
    grau: 247.5
  },
  {
    nome: 'Oeste',
    sigla: 'W',
    grau: 270
  },
  {
    nome: 'Oeste-noroeste',
    sigla: 'WNW',
    grau: 292.5
  },
  {
    nome: 'Noroeste',
    sigla: 'NW',
    grau: 315
  },
  {
    nome: 'Norte-Noroeste',
    sigla: 'NNW',
    grau: 337.5
  },
];


export function nomeCoordPorSigla(sigla: string): string {
  const filtro = _16POINTS_OF_COMPASS.filter((coord: any) => {
    return coord.sigla === sigla;
  });
  return filtro.length === 1 ? filtro[0].nome : 'posição desconhecida';
}
