import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Luas } from './assets';

@Component({
  selector: 'astro',
  templateUrl: './astro.component.html',
  styleUrls: ['./astro.component.scss']
})
export class AstroComponent implements OnChanges {

  private luas: any = [
    {
      lua_id: 1,
      lua_nome: 'Lua Nova',
      lua_path: Luas.fase.nova
    },{
      lua_id: 2,
      lua_nome: 'Lua Crescente',
      lua_path: Luas.fase.crescente
    },{
      lua_id: 3,
      lua_nome: 'Lua Quarto Crescente',
      lua_path: Luas.fase.quartoCrescente
    },{
      lua_id: 4,
      lua_nome: 'Lua Crescente Gibosa',
      lua_path: Luas.fase.crescenteGibosa
    },{
      lua_id: 5,
      lua_nome: 'Lua Cheia',
      lua_path: Luas.fase.cheia
    },{
      lua_id: 6,
      lua_nome: 'Lua Minguante Gibosa',
      lua_path: Luas.fase.minguanteGibosa
    },{
      lua_id: 7,
      lua_nome: 'Lua Quarto Minguante',
      lua_path: Luas.fase.quartoMinguante
    },{
      lua_id: 8,
      lua_nome: 'Lua Minguante',
      lua_path: Luas.fase.minguante
    }
  ];
  @Input('localidade') public localidade: string = '...';
  @Input('dados') public dados: any;

  constructor() {
    console.log(this.luas)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dados) {
      if (changes.dados.firstChange === false && changes.dados.currentValue !== changes.dados.previousValue) {
        this.init(changes.dados.currentValue);
      }
    }
  }

  private init(dados: any): void {
    const getLua = this.luas.filter((item: any) => item.lua_id === dados.lua_id);
    if (getLua.length === 1) {
      delete dados.lua_id;
      this.dados.lua = getLua[0];
      console.log(this.dados);

      this.runImageTobase64();
      const fr = new FileReader();
    } else {

    }
  }

  private runImageTobase64(): void {
    const req = new XMLHttpRequest();
    req.open('GET', this.dados.lua.lua_image);
    req.responseType = 'blob';
    req.onload = (e) => {
      console.log(e);
    };
  }

}
/*

  data: "2022-01-07"
  lua_id: 2
  lua_iluminacao: "28"
  nascer_da_lua: "09:41:00"
  nascer_do_sol: "05:09:00"
  por_da_lua: "10:10:00"
  por_do_sol: "05:43:00"

*/
