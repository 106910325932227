<div class="previsao">
  <div class="previsao-content" *ngIf="tipo">
    <div *ngIf="tipo === 'dias'" class="dias">
      <span class="title">Previsão diária para {{ localidade }}</span>
      <div class="slots">
        <div *ngFor="let dia of dados" class="slot">
          <span [innerHtml]="dia.data | isToday"></span>
          <span class="temp">{{ dia.temperatura_media }}°C</span>
          <span class="temp-max-min">
            <span class="temp-max" toolTip="Máxima"
              >{{ dia.temperatura_maxima }}°C</span
            >
            -
            <span class="temp-min" toolTip="Mínima"
              >{{ dia.temperatura_minima }}°C</span
            >
          </span>
          <img [src]="dia.condicao_icon" [alt]="dia.condicao_texto" />
          <span class="texto">{{ dia.condicao_texto }}</span>
          <span class="item"
            ><b>UV</b> - <span [innerHtml]="dia.uv | uvIndex"></span
          ></span>
          <span class="item"><b>Umidade</b> - {{ dia.umidade_media }}%</span>
          <span class="item">
            <b>Vento</b> - {{ dia.velocidade_maxima_vento }}km/h
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="tipo === 'hoje' && dados" class="hoje">
      <span class="title">Previsão para hoje para {{ localidade }}</span>
      <div class="hoje-container">
        <span class="data">{{ dados.data | date: "dd/MM/YYY" }}</span>
        <div class="hoje-content">
          <div class="top">
            <span class="temp">
              <span class="number"
                >{{ dados.temperatura_media }}
                <small><sup>°C</sup></small></span
              >
              <span class="t-min-max">
                <span class="t-max" toolTip="Temperatura Máxima">
                  ⇑ {{ dados.temperatura_maxima }}°C
                </span>
                <span class="t-min" toolTip="Temperatura Mínima">
                  ⇓ {{ dados.temperatura_minima }}°C
                </span>
              </span>
            </span>
            <span>
              <img [src]="dados.condicao_icon" [alt]="dados.condicao_texto" />
              {{ dados.condicao_texto }}
            </span>
          </div>
          <ul class="bottom">
            <li>Média de Visibilidade de {{ dados.media_visibilidade }}km</li>
            <li>Precipitação de {{ dados.precipitacao }}mm</li>
            <li>Umidade média de {{ dados.umidade_media }}%</li>
            <li>UV : {{ dados.uv | uvIndex: "text" }}</li>
            <li>
              Velocidade máxima do vento de
              {{ dados.velocidade_maxima_vento }}km/h
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="tipo === 'hora' && dados" class="hora">
      <span class="title"
        >Previsão por hora - dia {{ dados[0].hora | date: "dd/MM/YYY" }}</span
      >
      <drop *ngFor="let hora of dados">
        <div title>
          <div class="drop-t">
            <div class="hora">
              {{ hora.hora | date: "HH:mm" }}
            </div>
            <div class="temperatura">{{ hora.temperatura }}°C</div>
            <div class="condicao">
              <img [src]="hora.condicao_icon" [alt]="hora.condicao_texto" />
              <span>{{ hora.condicao_texto }}</span>
            </div>
            <div class="chuva">
              <icone
                toolTip="Possibilidade de chuva"
                icon="possibilidade_chuva"
              ></icone
              >&nbsp;{{ hora.possibilidade_chuva }}%
            </div>
            <div class="vento">
              <icone toolTip="Vento" icon="vento"></icone>&nbsp;
              {{ getPosicao(hora.vento_direcao) }}
              {{ hora.vento_velocidade }}km/h
            </div>
          </div>
        </div>
        <div content>
          <div class="frame">
            <div class="item">
              <div class="left">
                <icone size="30px" icon="calor"></icone>
              </div>
              <div class="right">
                <span class="nome">Índice de calor</span>
                <span
                  class="txt"
                  [innerHtml]="heatIndex(hora.indice_de_calor)"
                ></span>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <icone size="30px" icon="nuvem"></icone>
              </div>
              <div class="right">
                <span class="nome">Nuvens</span>
                <span class="txt">{{ hora.nuvem }}%</span>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <icone size="30px" icon="nuvem_cima"></icone>
              </div>
              <div class="right">
                <span class="nome">Ponto de orvalho</span>
                <span class="txt">{{ hora.ponto_orvalho }}°C</span>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <icone size="30px" icon="gota"></icone>
              </div>
              <div class="right">
                <span class="nome">Precipitação</span>
                <span class="txt">{{ hora.precipitacao }}mm</span>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <icone size="30px" icon="barometro"></icone>
              </div>
              <div class="right">
                <span class="nome">Pressão do ar</span>
                <span class="txt">{{ hora.pressao }}mb</span>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <icone size="30px" icon="rajada_vento"></icone>
              </div>
              <div class="right">
                <span class="nome">Rajada de vento</span>
                <span class="txt">{{ hora.rajada_vento }}km/h</span>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <icone size="30px" icon="termometro"></icone>
              </div>
              <div class="right">
                <span class="nome">Sensação térmica</span>
                <span class="txt">{{ hora.sensacao_termina }}°C</span>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <icone size="30px" icon="umidade"></icone>
              </div>
              <div class="right">
                <span class="nome">Umidade do ar</span>
                <span class="txt">{{ hora.umidade }}%</span>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <icone size="30px" icon="fogo"></icone>
              </div>
              <div class="right">
                <span class="nome">Índice UV</span>
                <span
                  class="txt"
                  [innerHtml]="hora.uv | uvIndex: 'text'"
                ></span>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <icone size="30px" icon="vento_frio"></icone>
              </div>
              <div class="right">
                <span class="nome">Vento frio</span>
                <span class="txt">{{ hora.vento_frio }}°C</span>
              </div>
            </div>
            <div class="item">
              <div class="left">
                <icone size="30px" icon="bandeira"></icone>
              </div>
              <div class="right">
                <span class="nome">Visibilidade</span>
                <span class="txt">{{ hora.visibilidade }}km</span>
              </div>
            </div>
          </div>
        </div>
      </drop>
    </div>
  </div>
</div>
