import { Directive, ElementRef, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[toolTip]'
})
export class TooltipDirective {

  @HostListener('mousemove', ['$event']) mouseover(event) {
    this.destroyBox();
    if (event.pageX !== null && event.clientX != null) {
      this._elemento.nativeElement.style.cursor = 'help';
      this.createBox(this.toolTip, event.pageX, event.pageY)
    }
  }
  @HostListener('mouseleave') mouseleave() {
    this.destroyBox();
  }

  @Input('toolTip') private toolTip: string;

  constructor(
    private _elemento: ElementRef
  ) {

  }

  private createBox(message: string, x: number, y: number): void {
    const box = document.createElement('div');
    box.id = 'toolTip';
    box.innerHTML = message;
    box.style.position = 'absolute';
    box.style.left = (x + 'px');
    box.style.top = ((y - 30) + 'px');
    box.style.padding = '5px 10px';
    box.style.boxSizing = 'border-box';
    box.style.background = 'black';
    box.style.color = 'white';
    box.style.fontSize = '11px';
    document.body.appendChild(box);
  }
  private destroyBox(): void {
    const box = document.getElementById('toolTip');
    if (box) {
      box.remove();
    }
  }

}
