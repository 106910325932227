import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'drop',
  templateUrl: './drop.component.html',
  styleUrls: ['./drop.component.scss']
})
export class DropComponent implements OnInit {

  public isOpened: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
