import { Component, OnInit } from '@angular/core';
import { AxiosResponse } from 'axios';
import { KweatherService } from '../../modules/kweather';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public loading: boolean = false;
  public get location() {
    return JSON.parse(localStorage.getItem("location"));
  }

  public localidades: any;

  public atual: any;
  public previsao: any;

  constructor(
    private kweather: KweatherService
  ) {
    this.init();
  }

  ngOnInit() {
  }

  private init(): void {

    // verificar location
      // se n tem, carrega a lista num diálogo
      // se tiver carrega o resto de boa
    this.loading = true;
    this.kweather.getLocalidades().then((res: AxiosResponse) => {
      if (res.status === 200) {
        this.localidades = res.data;
        this.loading = false;
      }
    }).catch((err) => console.log(err));
    if (this.location) {
      this.loadTempLocation(this.location.localidade_id);
    }

  }

  public setLocation(localidade: any): void {
    localStorage.setItem("location", JSON.stringify(localidade));
    this.loadTempLocation(localidade.localidade_id);
  }

  public loadTempLocation(localidade_id: number): void {
    this.callAtual(localidade_id);
    this.callPrevisao(localidade_id);
  }

  public callAtual(localidade_id: number): void {
    this.kweather.getAtual(localidade_id).then((res: AxiosResponse) => {
      if (res.status === 200) {
        // console.log(res.data);
        this.atual = res.data;
      }
    }).catch((err) => console.log(err));
  }

  public callPrevisao(localidade_id: number): void {
    this.kweather.getPrevisao(localidade_id).then((res: AxiosResponse) => {
      if (res.status === 200) {
        console.log(res.data);
        this.previsao = res.data;
      }
    }).catch((err) => console.log(err));
  }

}
