<div class="today-container" *ngIf="dados">
  <div class="today-content">
    <div class="today-title">
      <span class="primary">Clima em {{ localidade }}</span>
      <span class="secondary">{{
        dados.atualizacao | date: "dd/MM/YYY - HH:mm:ss"
      }}</span>
    </div>
    <div class="today-middle">
      <div class="today-temperature">
        <div class="left">
          <span class="number">{{ dados.temperatura }}°</span>
          <span class="type">{{ dados.condicao_texto }}</span>
        </div>
        <div class="right">
          <div class="icon">
            <img [src]="dados.condicao_icon" [alt]="dados.condicao_texto" />
          </div>
        </div>
      </div>
      <div class="today-list">
        <table>
          <tr>
            <td>Sensação térmica</td>
            <td>{{ dados.sensacao_termica }}°C</td>
          </tr>
          <tr>
            <td>Umidade</td>
            <td>{{ dados.umidade }}%</td>
          </tr>
          <tr>
            <td>UV</td>
            <td><span [innerHtml]="dados.uv | uvIndex"></span></td>
          </tr>
          <tr>
            <td>Vento</td>
            <td>
              {{ dados.vento_graus }}° à
              {{ getPosicao(dados.vento_direcao) }} em
              {{ dados.vento_velocidade }}km/h
            </td>
          </tr>
          <tr>
            <td>Núvem</td>
            <td>{{ dados.nuvem }}%</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="today-bottom"></div>
  </div>
</div>
