import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'uvIndex'
})
export class UvIndexPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const tx = parseInt(value, 10);
    if (args === 'text') {
      if (tx <= 2) {
        return 'Baixo';
      } else if (tx > 2 && tx <= 5) {
        return 'Moderado';
      } else if (tx > 5 && tx <= 7) {
        return 'Alto';
      } else if (tx > 7 && tx <= 10) {
        return 'Muito Alto';
      } else if (tx > 10) {
        return 'Extremo';
      } else {
        return '">ERRO';
      }
    } else {
      let uv = '<span class="uv-chip ';
      if (tx <= 2) {
        uv += 'green">Baixo</span>';
      } else if (tx > 2 && tx <= 5) {
        uv += 'golden">Moderado</span>';
      } else if (tx > 5 && tx <= 7) {
        uv += 'orange">Alto</span>';
      } else if (tx > 7 && tx <= 10) {
        uv += 'red">Muito Alto</span>';
      } else if (tx > 10) {
        uv += 'purple">Extremo</span>';
      } else {
        uv += '">ERRO</span>';
      }
      return uv;
    }
  }

}
