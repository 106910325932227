import { Component, Input, OnInit } from '@angular/core';
import { nomeCoordPorSigla } from '../utils/compasso';

@Component({
  selector: 'previsao',
  templateUrl: './previsao.component.html',
  styleUrls: [
    './previsao.component.scss',
    './../utils/common.scss'
  ]
})
export class PrevisaoComponent implements OnInit {

  @Input() public tipo: 'hoje' | 'hora' | 'dias';
  @Input('localidade') public localidade: string = '...';
  @Input('dados') public dados: any;

  constructor() {
  }

  ngOnInit() {
  }

  public getPosicao(pos: string) {
    return nomeCoordPorSigla(pos);
  }

  public heatIndex(index: number) {
    if (index < 27) {
      return `<b>AGRADÁVEL</b>`;
    } else if (index >= 27 && index < 32) {
      return `<b>CUIDADO</b>, possibilidade de fadiga após exposição e atividade prolongadas`;
    } else if (index >= 32 && index < 41) {
      return `<b>CUIDADO EXTREMO</b>, hipertermia e caimbras de calor possíveis`;
    } else if (index >= 41 && index < 54) {
      return `<b>PERIGO</b>, hipertermia e caimbras de calor prováveis`;
    } else if (index >= 54) {
      return `<b>PERIGO EXTREMO</b>, hipertermia e caimbras de calor iminentes`;
    }
    return 'ERRO';
  }

}
/*
condicao_code: 1063
condicao_icon: "//cdn.weatherapi.com/weather/64x64/day/176.png"
condicao_texto: "Possibilidade de chuva irregular"
data: "2022-01-04"
localidade_id: 1
media_visibilidade: "10"
possibilidade_chuva: 88
possibilidade_neve: 0
precipitacao: "3"
previsao_dia_id: 50
temperatura_maxima: "31"
temperatura_media: "28"
temperatura_minima: "25"
umidade_media: 69
uv: "11"
vai_chover: 1
vai_nevar: 0
velocidade_maxima_vento: "18"


atualizacao: "2022-01-04 16:00:00"
atualizacao_unix: 1641319200
condicao_code: 1003
condicao_icon: "//cdn.weatherapi.com/weather/64x64/day/116.png"
condicao_texto: "Parcialmente nublado"
is_day: 1
localidade_id: 1
nuvem: 50
sensacao_termica: "32"
temperatura: "29"
tempo_id: 409
umidade: 70
uv: "6"
vento_direcao: "E"
vento_graus: 100
vento_velocidade: "15"
*/
