import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isToday'
})
export class IsTodayPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    const date_f = value.split('-');
    const hoje_f = (date_f[2] + '/' + date_f[1] + '/' + date_f[0]).toString();

    const date = new Date();
    const dia = date.getDate() <= 9 ? ('0' + date.getDate()) : date.getDate();
    const mes = date.getMonth() <= 9 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1);
    const hoje = (dia + '/' + mes + '/' + date.getFullYear()).toString();
    return (hoje_f === hoje) ? '<b>HOJE</b>' : hoje_f;
  }

}
