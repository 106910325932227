<loading *ngIf="loading"></loading>
<mat-toolbar>
  <span>Tempo</span>
  <span class="spacer"></span>
  <button
    mat-button
    type="button"
    [disabled]="!localidades"
    [matMenuTriggerFor]="settingsMenu"
  >
    <mat-icon>place</mat-icon>&nbsp;{{
      location ? location.localidade_nome : "Localização"
    }}
  </button>
  <mat-menu #settingsMenu>
    <menu
      mat-menu-item
      *ngFor="let localidade of localidades"
      (click)="setLocation(localidade)"
      >{{ localidade.localidade_nome }}</menu
    >
  </mat-menu>
</mat-toolbar>
<main *ngIf="location">
  <today
    *ngIf="atual"
    [dados]="atual"
    [localidade]="location ? location.localidade_nome : null"
  ></today>
  <previsao
    tipo="dias"
    [dados]="previsao?.dias"
    [localidade]="location ? location.localidade_nome : null"
  ></previsao>
  <previsao
    tipo="hoje"
    [dados]="previsao?.hoje"
    [localidade]="location ? location.localidade_nome : null"
  ></previsao>
  <previsao
    tipo="hora"
    [dados]="previsao?.hoje_horas"
    [localidade]="location ? location.localidade_nome : null"
  ></previsao>
  <astro
    [dados]="previsao?.astro"
    [localidade]="location ? location.localidade_nome : null"
  ></astro>
</main>
<div class="no-location" *ngIf="!location">
  <p>Você precisa selecionar uma localização para continuar</p>
</div>
