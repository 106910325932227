<div class="astro-container">
  <div class="astro-content" *ngIf="localidade && dados">
    <span class="title"
      >Previsão dos astros para {{ localidade }} no dia
      {{ dados.data | date: "dd/MM/YYY" }}</span
    >
    <div class="double">
      <div class="side">
        <div class="image">
          <img [src]="dados.lua.lua_path" [alt]="dados.lua.lua_nome" />
        </div>
        <span class="moon"
          >A lua de hoje é <b>{{ dados.lua.lua_nome }}</b></span
        >
      </div>
      <div class="side">
        <ul class="linhas">
          <li>
            <span>A Iluminação da Lua é de {{ dados.lua_iluminacao }}%</span>
          </li>
          <li>
            <span
              ><icone icon="sol_nascer" toolTip="Nascer do Sol"></icone>&nbsp;{{
                dados.nascer_do_sol
              }}</span
            >
            <span
              ><icone icon="sol_por" toolTip="Pôr do Sol"></icone>&nbsp;{{
                dados.por_do_sol
              }}</span
            >
          </li>
          <li>
            <span
              ><icone icon="lua_nascer" toolTip="Nascer da Lua"></icone>&nbsp;{{
                dados.nascer_da_lua
              }}</span
            >
            <span
              ><icone icon="lua_por" toolTip="Pôr da Lua"></icone>&nbsp;{{
                dados.por_da_lua
              }}</span
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
