/**
  Lista de imagens da lua em base64
*/
import * as nova from './nova';
import * as crescente from './crescente';
import * as quartoCrescente from './quarto-crescente';
import * as crescenteGibosa from './crescente-gibosa';
import * as cheia from './cheia';
import * as minguanteGibosa from './minguante-gibosa';
import * as quartoMinguante from './quarto-minguante';
import * as minguante from './minguante';

export class Luas {

  public static fase = {
    nova: nova.nova,
    crescente: crescente.crescente,
    quartoCrescente: quartoCrescente.quartoCrescente,
    crescenteGibosa: crescenteGibosa.crescenteGibosa,
    cheia: cheia.cheia,
    minguanteGibosa: minguanteGibosa.minguanteGibosa,
    quartoMinguante: quartoMinguante.quartoMinguante,
    minguante: minguante.minguante,
  };

  constructor() {

  }
}
