import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KweatherService } from './kweather.service';
import { TodayComponent } from './components/today/today.component';
import { PrevisaoComponent } from './components/previsao/previsao.component';
import { IconeComponent } from './components/icone/icone.component';
import { IsTodayPipe } from './Pipes/isToday.pipe';
import { UvIndexPipe } from './Pipes/uv-index.pipe';
import { TooltipDirective } from './directives/tooltip.directive';
import { DropComponent } from './components/utils/drop/drop.component';
import { AstroComponent } from './components/astro/astro.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    TodayComponent,
    PrevisaoComponent,
    AstroComponent,
    IconeComponent,
    IsTodayPipe,
    UvIndexPipe,
    TooltipDirective,
    DropComponent,
  ],
  providers: [
    KweatherService
  ],
  exports: [
    TodayComponent,
    PrevisaoComponent,
    IconeComponent,
    AstroComponent,
  ]
})
export class KweatherModule { }
