import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'icone',
  templateUrl: './icone.component.html',
  styleUrls: [
    './icone.component.scss',
    './weather-icons-wind.scss',
    './weather-icons.scss'
  ]
})
export class IconeComponent implements OnInit {

  @Input('icon') public icon: string;
  @Input('size') public size: string;
  public icons: any = [
    {
      name: 'possibilidade_chuva',
      ref: 'wi-night-sleet'
    },
    {
      name: 'vento',
      ref: 'wi-strong-wind'
    },
    {
      name: 'termometro',
      ref: 'wi-thermometer'
    },
    {
      name: 'nuvem',
      ref: 'wi-cloud'
    },
    {
      name: 'celsius',
      ref: 'wi-celsius'
    },
    {
      name: 'rajada_vento',
      ref: 'wi-strong-wind'
    },
    {
      name: 'gota',
      ref: 'wi-raindrop'
    },
    {
      name: 'calor',
      ref: 'wi-hot'
    },
    {
      name: 'umidade',
      ref: 'wi-humidity'
    },
    {
      name: 'fogo',
      ref: 'wi-fire'
    },
    {
      name: 'bandeira',
      ref: 'wi-small-craft-advisory'
    },
    {
      name: 'barometro',
      ref: 'wi-barometer'
    },
    {
      name: 'vento_frio',
      ref: 'wi-sandstorm'
    },
    {
      name: 'nuvem_cima',
      ref: 'wi-cloud-up'
    },


    {
      name: 'sol_nascer',
      ref: 'wi-sunrise'
    },
    {
      name: 'sol_por',
      ref: 'wi-sunset'
    },
    {
      name: 'lua_nascer',
      ref: 'wi-moonrise'
    },
    {
      name: 'lua_por',
      ref: 'wi-moonset'
    },
  ];

  public classe: string = 'wi-night-sleet';

  constructor() { }

  ngOnInit() {
  }

  public getIcon(icon: string): string {
    const filter = this.icons.filter((item) => item.name === icon);
    if (filter.length > 0) {
      return filter[0].ref;
    }
    return null;
  }



}
